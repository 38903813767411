import React from "react";
import Hyphenated from "react-hyphen";
import "aos/dist/aos.css";
import pl from "hyphenated-pl";
import styled from "styled-components";
import PropTypes from "prop-types";

function Chapter({ description, Title, img }) {
  return (
    <ChapterContainer>
      <TitleStyled>{Title} </TitleStyled>
      <Hyphenated language={pl}>
        <Desc>{description}</Desc>
      </Hyphenated>
    </ChapterContainer>
  );
}

Chapter.propTypes = {
  description: PropTypes.string,
  Title: PropTypes.string,
  img: PropTypes.string,
};
export default Chapter;

const ChapterContainer = styled.div`
  display: block;
  @media only screen and (max-width: 380px) {
    padding: 5px 0 5px 0;
  }
`;

const TitleStyled = styled.h2`
  text-align: center;
  font-size: 5rem;
  color: $text-color;
  font-family: "Fredoka", sans-serif;
  color: #6a4fd4;
  border-bottom: 2px solid #6a4fd4;
  margin-bottom: 30px;
  @media only screen and (max-width: 765px) {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 540px) {
    font-size: 2rem;
    text-align: left;
    padding-top: 5px;
  }
`;

const Desc = styled.p`
  position: relative;
  padding: 20px 20px;
  color: $text-color;
  font-weight: 400;
  font-size: 1.3rem;
  font-family: "Roboto" sans-serif;
  text-align: justify;
  width: 100%;
@media only screen and (max-width: 400px) {
  padding: 0 0;
  font-size: 1.1rem;
}

  @media only screen and (min-width: 670px) {
    font-size: 1.5rem;
  }
  @media only screen and (min-width: 830px) {
    display: flex;
    justify-content: center;
    justify-self: center;
    width: 100%;
    margin: 20px auto 20px auto;
  }
  @media only screen and (min-width: 1100px) {
    width: 75%;
  }
  @media only screen and (min-width: 1500px) {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 75%;
  }
`;
