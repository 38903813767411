import { graphql } from "gatsby";
import React from "react";
import AdviceBox from "../components/Porady/AdiviceBox";
import Chapter from "../components/Porady/Chapter.js";
import styled from "styled-components";
import Footer from "../components/Footer";
import { TitleSubTitle, adviceContent } from "../data/PoradyData.js";
import NavbarSticky from "../components/NavbarSticky.js";
import {COLORS} from "../styles/Colors.js";
import { ThemeProvider } from "styled-components";

const theme = {colors: COLORS};


function Porady({ data }) {
  return (
    <ThemeProvider theme={theme}>
      <NavbarSticky isSticky={true} isHide ShowOnScroll />
      <Wrapper>
        <Chapter {...TitleSubTitle} />
        <Columns>
          {adviceContent.map((item, index) => {
            const foundID = data.allImageSharp.edges.find(
              (obj) => obj.node.id == item.id
            );
            const fluidImage = foundID.node.fluid;
            return (
              <Column key={index}>
                <AdviceBox
                  key={index}
                  anchorTo={item.url}
                  fluid={fluidImage}
                  date={item.date}
                  timeConsumed={item.timeConsumed}
                  subTitle={item.subTitle}
                  title={item.title}
                />
              </Column>
            );
          })}
        </Columns>
      </Wrapper>
      <Footer />
    </ThemeProvider>
  );
}

export const getImageData = graphql`
  {
    allImageSharp {
      edges {
        node {
          id

          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default Porady;

const Wrapper = styled.div`
  padding: 20px;
  @media only screen and (max-width: 765px) {
    padding: 20px 40px;
  }
  @media only screen and (max-width: 400px) {
    padding: 10px 20px;

  }
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 0;
  @media screen and (min-width: 900px) {
    padding: 0 150px;
  }
`;

const Column = styled.div`
  flex: 1;
  margin: 2px;
  padding: 10px;

  float: left;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1280px) {
    margin-bottom: 5px;
    flex-basis: 40%;

    &:nth-last-child(2) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
  }
  @media screen and(min-width: 1100px) {
    flex-basis: 30%;
  }
`;
