import React from "react";
import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { MdOutlineDateRange } from "react-icons/md";
import { IoTime } from "react-icons/io5";

import Img from "gatsby-image";

function AdviceBox({
  title,
  subTitle,
  fluid,
  anchorKey,
  anchorTo,
  anchorTitle,
  timeConsumed,
  date,
}) {
  const textLimiter = (text, maxLength) => {
    
    if (text.length <= maxLength) {
      return text + '...';
    }
    const lastSpaceIndex = text.lastIndexOf(" ", maxLength);
    if (lastSpaceIndex === -1) {
      return text.slice(0, maxLength) + '...';
    } else {
      return text.slice(0, lastSpaceIndex) + '...';
    }
  };

  console.log(textLimiter);

  return (
    <AnchorLinkStyled key={anchorKey} to={anchorTo} title={anchorTitle}>
      <AdviceContainer>
        <Title>{title}</Title>
        <ImageStyled fluid={fluid} />
        <DateTimeBox>
          <SmallBox>
            <IoTime />
            <Text>{timeConsumed}</Text>
          </SmallBox>
          <SmallBox>
            <MdOutlineDateRange />
            <Text>{date}</Text>
          </SmallBox>
        </DateTimeBox>
        <Description>{textLimiter(subTitle, 240)}</Description>
      </AdviceContainer>
    </AnchorLinkStyled>
  );
}

export default AdviceBox;

const Text = styled.div`
  margin-left: 10px;
  display: block;
  font-size: 1.1rem;
`;

const SmallBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const DateTimeBox = styled.div`
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 30px;
  color: #ddd;
  font-size: 1.4rem;
`;

const AnchorLinkStyled = styled(AnchorLink)`
  text-decoration: none;
  color: #ffe646;
`;

const ImageStyled = styled(Img)`
  -webkit-filter: brightness(80%);
  filter: brightness(80%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;

  :hover {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }
`;

const AdviceContainer = styled.div`
  margin: 50px 0;
  border-radius: 5px;
  box-shadow: 0 10px 40px -15px rgba(#818181, 0.5);
  min-height: 330px;
  min-width: 300px;
  background-color: ${(props) => props.theme.colors.lightPurple};
  transition: background-color 0.3s ease;

  :hover {
    background-color: ${(props) => props.theme.colors.middlePurple};
    box-shadow: none;
    color: ${(props) => props.theme.colors.yellow};
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin: 10px 0;
    font-size: 1rem;
  }
`;

const Title = styled.h2`
  padding: 10px;
  padding-left: 18px;
  font-size: 1.3rem;
  padding-bottom: 5px;
  @media screen and (min-width: 665px) and (max-width: 1200px) {
    font-size: 1.1rem;
    font-weight: 700;
  }
`;

const Description = styled(Title)`
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  @media screen and (min-width: 665px) and (max-width: 1200px) {
    font-size: 1rem;
    font-weight: 500;
  }
`;
